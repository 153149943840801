import React from "react"
import ReactMarkdown from "react-markdown"
import StandardLayout from "../layouts/standard"
import { Link } from "gatsby"

const NewsItem = newsItem => {
  const { title, thumbnail, content } = newsItem

  return (
    <div
      className="uk-card uk-card-default bg-white uk-padding-small uk-grid-collapse"
      data-uk-grid
    >
      <div className="uk-width-1-1 uk-width-1-3@s">
        <div className="image-container news-item">
          <img src={thumbnail && thumbnail.publicURL} />
        </div>
      </div>
      <div className="uk-width-1-1 uk-width-expand@s">
        <div className="ph4-ns mt4 mt0-ns">
          <h1>{title}</h1>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default function NewsPage({ pageContext }) {
  const { datum, pageNumber, totalPages, prefix } = pageContext

  const Paginator = props => (
    <div className="w-100 flex justify-center mv4 ">
      <div className="flex w-30">
        <div className="w-33 flex justify-center">
          {pageNumber > 1 ? (
            <Link to={prefix + (pageNumber - 1)}>
              <button className="uk-button uk-button-default bg-white">
                <span uk-icon="icon: chevron-left"></span>
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>

        <div className="w-33 flex justify-center items-center">
          {pageNumber} / {totalPages}
        </div>

        <div className="w-33 flex justify-center">
          {pageNumber < totalPages ? (
            <Link to={prefix + (pageNumber + 1)}>
              <button className="uk-button uk-button-default bg-white">
                <span uk-icon="icon: chevron-right"></span>
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  )

  return (
    <StandardLayout title="News">
      <section className="uk-section raleway">
        <div className="uk-container">
          <h1 className="color-primary uppercase mb3">News</h1>
          <p className="mt0">All news coverage of Stylam</p>

          <Paginator />

          <div className="mt4 mb5">
            {datum.map(newsItem => (
              <NewsItem {...newsItem} />
            ))}
          </div>

          <Paginator />
        </div>
      </section>
    </StandardLayout>
  )
}
